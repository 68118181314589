import React from 'react';
import { HelmetHeader } from '../../Components';
import { Input, Group, FormLayout, FormItem, Button, FormStatus } from '@vkontakte/vkui';
import { useUser } from '../../models';
import { login } from "../../models/user";

export const Unauthorized = () => {
  const { loading, loginError } = useUser();

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const target = event.target as typeof event.target & {
      login: { value: string };
      password: { value: string };
    };

    const loginData = { password: target.password.value, login: target.login.value };
    login(loginData);
  };

  return (
    <div>
      <HelmetHeader title="useMemo login" hasAfter={false} />
      <Group>
        <FormLayout onSubmit={onSubmit}>
          <FormItem top="Логин">
            <Input name="login" type="text" required />
          </FormItem>
          <FormItem top="Пароль">
            <Input name="password" type="password" required />
          </FormItem>
          <FormItem>
            <Button size="l" stretched loading={loading} type="submit">
              Войти
            </Button>
          </FormItem>
          {loginError && <FormStatus mode="error">{loginError}</FormStatus>}
        </FormLayout>
      </Group>
    </div>
  );
};
