import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

export function useQuery() {
  const [search, setSearch] = useSearchParams();

  return useMemo(() => {
    const params = new URLSearchParams(search);
    return [Object.fromEntries(params), setSearch] as const;
  }, [search]);
}
