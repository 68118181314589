import React, { Dispatch, SetStateAction, useEffect, useState, useRef } from 'react';
import { HelmetHeader } from '../../Components';
import {
  DatePicker,
  DateRangeInput,
  FormItem,
  Group,
  Platform,
  Spinner,
  SimpleCell,
  classNames,
} from '@vkontakte/vkui';
import { addDays, subDays } from '@vkontakte/vkui/dist/lib/date';
import { DateObj, EventType } from '../../types';
import { getCustomPlatform } from '../../utils/getCustomPlatform';
import { fromDateToObj, fromObjToDate, maxDate, minDate } from '../../utils/getDate';
import { getEventsByDates } from '../../utils/api';
import { Icon20NotebookCheckOutline } from '@vkontakte/icons';
import { formatMyDate, parseFormatDate } from '../../utils/formateDate';
import styles from './Events.module.css';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../hooks/useQuery';

const getCellAfter = (event: EventType) => {
  const currentDateObj = fromDateToObj();
  const currentDate = +fromObjToDate(currentDateObj);
  const diffDays = Math.round((event.touchDate - currentDate) / (1000 * 60 * 60 * 24));
  const isToday = diffDays === 0;
  return { isToday, afterText: isToday ? 'Сегодня' : `${diffDays}` };
};

const initDateFrom = subDays(new Date(), 7);
const initDateTo = addDays(new Date(), 365);

export const Events = () => {
  const [events, setEvents] = useState<EventType[]>([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useQuery();
  const [dateFrom, setDateFrom] = useState(() => parseFormatDate(query.dateFrom) || initDateFrom);
  const [dateTo, setDateTo] = useState(() => parseFormatDate(query.dateTo) || initDateTo);
  const reqId = useRef(+dateTo - +dateFrom);
  const platform = getCustomPlatform();
  const navigate = useNavigate();
  const isDesktop = platform === Platform.VKCOM;

  const handleCalendarChange = (dates?: (Date | null)[]) => {
    if (!dates || !dates[0] || !dates[1]) {
      setDateFrom(initDateFrom);
      setDateTo(initDateTo);
      return;
    }
    const [dF, dT] = dates;
    setDateFrom(dF);
    setDateTo(dT);
  };
  const changeDateFromPicker = (date: DateObj, setDate: Dispatch<SetStateAction<Date>>) => setDate(fromObjToDate(date));

  useEffect(() => {
    setLoading(true);
    reqId.current = +dateTo - +dateFrom;
    const localReqId = reqId.current;

    setQuery(
      { dateFrom: formatMyDate(fromDateToObj(dateFrom)), dateTo: formatMyDate(fromDateToObj(dateTo)) },
      { replace: true },
    );

    getEventsByDates(fromDateToObj(dateFrom), fromDateToObj(dateTo))
      .then(({ data }) => {
        if (reqId.current !== localReqId) {
          return;
        }
        setEvents(data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setEvents([]);
        setLoading(false);
      });
  }, [dateFrom, dateTo]);

  return (
    <>
      <HelmetHeader title="События" hasBack />
      {isDesktop ? (
        <Group>
          <FormItem top="Выберите даты">
            <DateRangeInput
              value={[dateFrom, dateTo]}
              onChange={handleCalendarChange}
              disablePast={false}
              disableFuture={false}
              closeOnChange={false}
              disablePickers={false}
              disableCalendar={false}
            />
          </FormItem>
        </Group>
      ) : (
        <Group>
          <FormItem top="Начальная дата">
            <DatePicker
              min={minDate}
              max={fromDateToObj(dateTo)}
              defaultValue={fromDateToObj(dateFrom)}
              onDateChange={value => changeDateFromPicker(value, setDateFrom)}
            />
          </FormItem>
          <FormItem top="Конечная дата">
            <DatePicker
              min={fromDateToObj(dateFrom)}
              max={maxDate}
              defaultValue={fromDateToObj(dateTo)}
              onDateChange={value => changeDateFromPicker(value, setDateTo)}
            />
          </FormItem>
        </Group>
      )}
      <Group>
        {loading ? (
          <Spinner />
        ) : (
          <div>
            {events.map(event => {
              const formatDate = formatMyDate(event.date);
              const { isToday, afterText } = getCellAfter(event);
              return (
                <SimpleCell
                  key={event._id + event.touchDate}
                  before={<Icon20NotebookCheckOutline style={event.date.annual ? {} : { visibility: 'hidden' }} />}
                  after={afterText}
                  multiline
                  subtitle={event.title}
                  className={classNames(isToday && styles.today)}
                  onClick={() => navigate(`/item/${event.itemId}?el=${event._id}`)}
                >
                  {formatDate}
                  {event.name ? ` - ${event.name}` : ''}
                  {event.years !== undefined ? ` [${event.years}]` : ''}
                </SimpleCell>
              );
            })}
          </div>
        )}
      </Group>
    </>
  );
};
