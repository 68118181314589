import { useEffect } from 'react';
import { useStore } from 'effector-react';
import { $popout, setPopout, clearPopout } from './popout';

export const usePopout = () => {
  useEffect(() => clearPopout, []);

  return {
    popout: useStore($popout),
    setPopout,
    clearPopout,
  };
};
