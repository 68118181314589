import { CSSProperties } from 'react';
import { Div as VKUIDiv, DivProps } from '@vkontakte/vkui';

interface MyDivProps extends DivProps {
  mode?: 'default' | 'vertical' | 'horizontal';
  spacing?: number;
}

export const MyDiv = ({ children, mode, spacing = 0, style, ...rest }: MyDivProps) => {
  const localStyle: CSSProperties = {};

  switch (mode) {
    case 'horizontal':
      localStyle.paddingTop = spacing;
      localStyle.paddingBottom = spacing;
      break;
    case 'vertical':
      localStyle.paddingLeft = spacing;
      localStyle.paddingRight = spacing;
      break;
  }

  return (
    <VKUIDiv {...rest} style={{ ...style, ...localStyle }}>
      {children}
    </VKUIDiv>
  );
};
