import { Platform } from '@vkontakte/vkui';

export const getCustomPlatform = () => {
  if (/Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return Platform.ANDROID;
  } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    return Platform.IOS;
  } else {
    return Platform.VKCOM;
  }
};
