import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { HelmetHeader } from '../../Components';
import { FormStatus, Spinner, Title, Div, Group, Link } from '@vkontakte/vkui';
import { ItemType } from '../../types';
import { getSharedItem } from '../../utils/api';
import { Content } from '../Item/Content';

export const SharedItem = () => {
  const { itemId } = useParams();
  const [item, setItem] = useState<ItemType | null>(null);
  const perm = useRef(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!itemId) {
      return;
    }
    setLoading(true);
    getSharedItem(itemId)
      .then(({ data: { item, hasPerm } }) => {
        perm.current = hasPerm;
        setItem(item);
      })
      .catch(error => setError(error.message))
      .finally(() => setLoading(false));
  }, [itemId]);

  if (!item && !loading && error) {
    return (
      <>
        <HelmetHeader title="Ошибка" />
        <FormStatus mode="error">{error}</FormStatus>
      </>
    );
  }

  if (!item || loading) {
    return (
      <>
        <HelmetHeader title="Загрузка..." />
        <Spinner size="large" />
      </>
    );
  }

  return (
    <div>
      <HelmetHeader title="useMemo by SerPuh" />
      {perm.current && (
        <Group>
          <Div>
            <Link href={`/item/${itemId}`}>Перейти на страницу</Link>
          </Div>
        </Group>
      )}
      <Group>
        <Div>
          <Title level="2">{item.title}</Title>
        </Div>
      </Group>
      <Group>
        <Content content={item.content} isEdit={false} />
      </Group>
    </div>
  );
};
