import React, { ChangeEvent, useState } from 'react';
import { DateContentItem } from '../../../types';
import {Checkbox, DatePicker, Input, Spacing, Subhead} from '@vkontakte/vkui';
import { TimeSelect } from '../../../Components';
import { minDate, maxDate } from '../../../utils/getDate';
import styles from './Content.module.css';

export const DateEdit = ({ item }: { item: DateContentItem }) => {
  const [hasTime, setHasTime] = useState(item.date.hour !== undefined);

  const handleTimeCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setHasTime(checked);
    item.date.hour = checked ? 12 : undefined;
    item.date.minute = checked ? 0 : undefined;
  };
  const onChangeTime = ({ name, value }: { name: string; value: string }) => {
    // @ts-ignore
    item.date[name] = Number(value);
  };
  return (
    <>
      <Subhead>Дата</Subhead>
      <Spacing size={5} />
      <Input
        autoFocus={item.name === ''}
        defaultValue={item.name}
        onChange={event => (item.name = event.target.value)}
        placeholder="Название события"
      />
      <Spacing size={8} />
      <DatePicker
        min={minDate}
        max={maxDate}
        defaultValue={item.date}
        onDateChange={value => {
          item.date.year = value.year;
          item.date.month = value.month;
          item.date.day = value.day;
        }}
      />
      <Checkbox
        defaultChecked={item.date.annual}
        onChange={event => (item.date.annual = event.target.checked)}
        className={styles.dateCheckbox}
        hasHover={false}
        hasActive={false}
      >
        Ежегодное событие
      </Checkbox>
      <Checkbox
        checked={hasTime}
        onChange={handleTimeCheckbox}
        className={styles.dateCheckbox}
        hasHover={false}
        hasActive={false}
      >
        Добавить время
      </Checkbox>
      {hasTime && (
        <TimeSelect
          onChangeTime={onChangeTime}
          defaultHourValue={item.date.hour || 12}
          defaultMinValue={item.date.minute || 0}
        />
      )}
    </>
  );
};
