import { ContentItem } from '../types';

export const reorderList = (
  { from, to }: { from: number; to: number },
  list: ContentItem[],
  updateListFn: (items: ContentItem[]) => void,
) => {
  const _list = [...list];
  _list.splice(from, 1);
  _list.splice(to, 0, list[from]);
  updateListFn(_list);
};
