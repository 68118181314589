import React, { useEffect } from 'react';
import { useFavorites } from '../../models/useFavorites';
import { Spinner, Group, Title, Div } from '@vkontakte/vkui';
import { MyDiv } from '../MyDiv';
import { ItemsOne } from '../../Pages';

export const Favorites = () => {
  const { favorites, loading, getFavorites } = useFavorites();

  useEffect(getFavorites, []);

  if (loading) {
    return (
      <Group>
        <MyDiv mode="horizontal">
          <Spinner size="medium" />
        </MyDiv>
      </Group>
    );
  }

  if (favorites.length === 0) {
    return null;
  }

  return (
    <Group>
      <Div>
        <Title level="3">Избранное</Title>
      </Div>
      {favorites.map(item => {
        return <ItemsOne key={item._id} item={item} getItems={getFavorites} />;
      })}
    </Group>
  );
};
