import { ContentItem, ItemType, TagType } from '../../types';
import { rootDomain } from '../root';
import { getItem as getItemAPI, saveItem as saveItemAPI, deleteItem as deleteItemAPI } from '../../utils/api';
import { sample } from 'effector';

const newItem: Partial<ItemType> = {
  _id: 'new',
  shared: false,
  deleted: false,
  title: '',
  content: [],
  shadowTags: [],
  tags: [],
};

const itemDomain = rootDomain.createDomain('item');

export const $item = itemDomain.createStore<ItemType | null>(null);
export const $itemLoading = itemDomain.createStore(true);
export const $fetchError = itemDomain.createStore<string | null>(null);

export const clearItemData = itemDomain.createEvent('on_destroy');
itemDomain.onCreateStore(store => store.reset([clearItemData]));

export const getItem = itemDomain.createEvent<string>();
export const getItemFx = itemDomain.createEffect(getItemAPI);
export const deleteItem = itemDomain.createEvent();
export const deleteItemFx = itemDomain.createEffect(deleteItemAPI);
export const setNewItem = itemDomain.createEvent();
export const fakeUpdateItem = itemDomain.createEvent();
export const saveItemToBackendFx = itemDomain.createEffect(saveItemAPI);
export const setItemTags = itemDomain.createEvent<TagType[]>();
export const addContentItemAtEnd = itemDomain.createEvent<ContentItem>();
export const addContentItemAtStart = itemDomain.createEvent<ContentItem>();
export const removeContentItem = itemDomain.createEvent<string>();
export const setTitle = itemDomain.createEvent<string>();
export const setContent = itemDomain.createEvent<ContentItem[]>();
export const setParentForNewTag = itemDomain.createEvent<{ id: string; parent?: TagType }>();

$item
  .on(setNewItem, () => newItem as ItemType)
  .on(setItemTags, (item, tags) => (item ? { ...item, tags } : item))
  .on(fakeUpdateItem, item => (item ? { ...item } : item))
  .on(setTitle, (item, title) => (item ? { ...item, title } : item))
  .on(getItemFx.doneData, (_, item) => item.data)
  .on(addContentItemAtEnd, (item, contentItem) => (item ? { ...item, content: [...item.content, contentItem] } : item))
  .on(addContentItemAtStart, (item, contentItem) =>
    item ? { ...item, content: [contentItem, ...item.content] } : item,
  )
  .on(setContent, (item, contentItems) => (item ? { ...item, content: contentItems } : item))
  .on(removeContentItem, (item, key) => {
    if (item) {
      item.content = item.content.filter(el => el._id !== key && el.key !== key);
    }
    return item ? { ...item } : item;
  })
  .on(deleteItemFx.done, item => (item ? { ...item, deleted: true } : item))
  .on(setParentForNewTag, (item, data) => {
    if (!item) {
      return item;
    }
    const tag = item?.tags.find(tag => tag._id === data.id);
    if (tag) {
      tag.parent = data.parent;
    }
    return item;
  });

$itemLoading
  .on(setNewItem, () => false)
  .on(getItemFx, () => true)
  .on(getItemFx.finally, () => false)
  .on(saveItemToBackendFx, () => true)
  .on(deleteItemFx, () => true)
  .on(deleteItemFx.finally, () => false)
  .on(saveItemToBackendFx.fail, () => false);

$fetchError
  .on(getItemFx, () => '')
  .on(getItemFx.fail, (_, { error }) => error.message)
  .on(deleteItemFx, () => '')
  .on(deleteItemFx.fail, (_, { error }) => error.message)
  .on(saveItemToBackendFx, () => '')
  .on(saveItemToBackendFx.fail, (_, { error }) => error.message);

sample({
  clock: getItem,
  filter: id => id !== 'new',
  target: getItemFx,
});

sample({
  clock: deleteItem,
  source: $item,
  fn: item => (item ? item._id : ''),
  target: deleteItemFx,
});

sample({
  clock: getItem,
  filter: id => id === 'new',
  target: setNewItem,
});

sample({
  clock: saveItemToBackendFx.doneData,
  source: $item,
  filter: (_, { data }) => data === true,
  fn: item => (item ? item._id : ''),
  target: getItem,
});
