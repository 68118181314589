import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonGroup, Group } from '@vkontakte/vkui';
import { Favorites, HelmetHeader, MyDiv } from '../../Components';
import { $user } from '../../models/user';
import { useStore } from 'effector-react';
import { UserParts } from '../../types';

export const Home = () => {
  const navigate = useNavigate();
  const { name, parts } = useStore($user);
  const hasGitPart = parts?.includes(UserParts.gitHelper);

  return (
    <>
      <HelmetHeader title={`useMemo - ${name}`} />
      <Group>
        <MyDiv mode="horizontal">
          <ButtonGroup mode="vertical" stretched gap="m">
            <Button stretched size="l" onClick={() => navigate('/tags')}>
              Тэги
            </Button>
          </ButtonGroup>
        </MyDiv>
      </Group>
      <Group>
        <MyDiv mode="horizontal">
          <ButtonGroup mode="vertical" stretched gap="m">
            <Button stretched size="l" onClick={() => navigate('/item/new')}>
              Новая запись
            </Button>
            <Button stretched size="l" onClick={() => navigate('/items')}>
              Поиск
            </Button>
          </ButtonGroup>
        </MyDiv>
      </Group>
      <Group>
        <MyDiv mode="horizontal">
          <ButtonGroup mode="vertical" stretched gap="m">
            <Button stretched size="l" onClick={() => navigate('/events')}>
              События
            </Button>
          </ButtonGroup>
        </MyDiv>
      </Group>
      {hasGitPart && (
        <Group>
          <MyDiv mode="horizontal">
            <ButtonGroup mode="vertical" stretched gap="m">
              <Button stretched size="l" onClick={() => navigate('/git_helper')}>
                Git helper
              </Button>
            </ButtonGroup>
          </MyDiv>
        </Group>
      )}
      <Favorites />
    </>
  );
};
