import { ContentItem, ContentType } from '../types';
import { makeId } from './makeId';
import { fromDateToObj } from './getDate';

export const getNewContentItem = (type: ContentType): ContentItem => {
  const key = makeId();
  switch (type) {
    case ContentType.checkbox:
      return {
        key,
        type,
        checked: false,
        name: '',
      };
    case ContentType.date:
      const { day, year, month } = fromDateToObj();
      return {
        key,
        type,
        name: '',
        date: {
          annual: false,
          year,
          month,
          day,
        },
      };
    // case ContentType.link:
    case ContentType.text:
    default:
      return {
        key,
        type,
        value: '',
        name: '',
      };
  }
};
