import React, { ChangeEvent, useState } from 'react';
import { Button, Div, FormItem, Radio, RadioGroup, ButtonGroup, Spacing } from '@vkontakte/vkui';
import { ContentItem, ContentType } from '../../types';
import { getNewContentItem } from '../../utils/getNewContentItem';

interface AddActionsProps {
  addContentItem: (content: ContentItem) => void;
  btnText: string;
}

const style = { paddingTop: 0, paddingBottom: 0 };
const types = Object.values(ContentType);

export const AddActions = ({ addContentItem, btnText }: AddActionsProps) => {
  const [isAdd, setIsAdd] = useState(false);
  const [currentType, setCurrentType] = useState(types[0]);

  const onRadioClick = (event: ChangeEvent<HTMLInputElement>) => setCurrentType(event.target.value as ContentType);
  const onAddClick = () => {
    const item = getNewContentItem(currentType);
    addContentItem(item);
    setIsAdd(false);
  };

  return isAdd ? (
    <FormItem top="Тип поля" style={style}>
      <RadioGroup>
        {types.map(type => (
          <Radio key={type} name="type" value={type} checked={currentType === type} onChange={onRadioClick}>
            {type}
          </Radio>
        ))}
      </RadioGroup>
      <Spacing size={12} />
      <ButtonGroup stretched>
        <Button stretched onClick={onAddClick}>
          Добавить
        </Button>
        <Button
          stretched
          mode="secondary"
          onClick={() => {
            setIsAdd(false);
          }}
        >
          Отменить
        </Button>
      </ButtonGroup>
    </FormItem>
  ) : (
    <Div style={style}>
      <Button stretched onClick={() => setIsAdd(true)}>
        {btnText}
      </Button>
    </Div>
  );
};
