import React, { CSSProperties } from 'react';
import { IconButton, SimpleCell, Cell } from '@vkontakte/vkui';
import { copyTextToClipboard } from '../../utils/copyTextToClipboard';
import { Icon24CopyOutline } from '@vkontakte/icons';
import { GitCommands } from './types';

interface CopyCellProps {
  text: string;
  onCopy: VoidFunction;
  selectable?: boolean;
  command?: GitCommands;
  checked?: boolean;
  onCommandCheck?: (command: GitCommands, checked: boolean) => void;
}

export const CopyCell = ({ text, onCopy, selectable, command, checked, onCommandCheck }: CopyCellProps) => {
  const onClick = () => {
    void copyTextToClipboard(text);
    onCopy();
  };

  const props = {
    hasActive: true,
    multiline: true,
    after: (
      <IconButton onClick={onClick}>
        <Icon24CopyOutline />
      </IconButton>
    ),
    style: { whiteSpace: 'pre-wrap', cursor: 'text' } as CSSProperties,
  };

  return selectable ? (
    <Cell
      mode="selectable"
      checked={checked}
      onChange={() => {
        if (onCommandCheck && command) {
          onCommandCheck?.(command, !checked);
        }
      }}
      {...props}
    >
      {text}
    </Cell>
  ) : (
    <SimpleCell {...props}>{text.replaceAll(' && ', '\n').replaceAll(' ; ', '\n')}</SimpleCell>
  );
};
