import { rootDomain } from '../root';
import { ReactNode } from 'react';

const popoutDomain = rootDomain.createDomain('popout');

export const $popout = popoutDomain.createStore<ReactNode>(null);

export const clearPopout = popoutDomain.createEvent();
popoutDomain.onCreateStore(store => store.reset([clearPopout]));

export const setPopout = popoutDomain.createEvent<ReactNode>();

$popout.on(setPopout, (_, popout) => popout);
