import { Div, Group } from '@vkontakte/vkui';
import { useRouteError } from 'react-router-dom';
import { HelmetHeader } from '../../Components';
import React from 'react';

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <>
      <HelmetHeader title="Oops!" hasBack />
      <Group>
        <Div>
          <p>Страница не найдена</p>
        </Div>
      </Group>
    </>
  );
};
