export const replaceLinks = (text: string) => {
  const pattern =
    /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z()]{1,6}\b([-a-zA-Z0-9()@:;%_\+.~#?&//=]*)/g;

  const urls = text.match(pattern);

  if (urls) {
    urls.forEach(elem => {
      if (elem.match(/^https?\:\/\//i)) {
        text = text.replace(elem, '<a href="$&" target="_blank">$&</a>');
      } else {
        text = text.replace(elem, '<a href="//$&" target="_blank">$&</a>');
      }
    });
  }

  return text;
};
