import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { ErrorPage, Home, Item, Unauthorized, Items, Tags, TagWrap, SharedItem, Events, GitHelper } from './Pages';
import { App } from './App';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'item/:itemId',
        element: <Item />,
      },
      {
        path: 'items',
        element: <Items />,
      },
      {
        path: 'events',
        element: <Events />,
      },
      {
        path: 'tags',
        element: <Tags />,
      },
      {
        path: 'tag/:tagId',
        element: <TagWrap />,
      },
      {
        path: 'shared/:itemId',
        element: <SharedItem />,
      },
      {
        path: 'git_helper',
        element: <GitHelper />,
      },
    ],
  },
]);

export const unauthorizedRouter = createBrowserRouter([
  {
    path: 'shared/:itemId',
    element: <SharedItem />,
  },
  {
    path: '/*',
    element: <Unauthorized />,
  },
]);
