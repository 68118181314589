import { sample } from 'effector';
import { Login, User } from '../../types';
import { rootDomain } from '../root';
import { login as loginAPI, getUserData } from '../../utils/api';
import { logout } from '../logout';
import { getTags } from '../tag';

const userDomain = rootDomain.createDomain('user');

const userInitial = null as unknown as User;

export const $user = userDomain.createStore<User>(userInitial);
export const $userLoading = userDomain.createStore(false);
export const $userInitialLoading = userDomain.createStore(true);
export const $loginError = userDomain.createStore<string | null>(null);

export const getUser = userDomain.createEvent();
export const login = userDomain.createEvent<Login>();

export const loginFx = userDomain.createEffect(loginAPI);
export const getUserDataFx = userDomain.createEffect(getUserData);

$user
  .on(getUserDataFx.doneData, (_, user) => user.data)
  .on(getUserDataFx.failData, () => userInitial)
  .on(logout, () => userInitial);

$userInitialLoading.on(getUserDataFx.finally, () => false);

$userLoading
  .on(getUserDataFx, () => true)
  .on(loginFx, () => true)
  .on(getUserDataFx.finally, () => false)
  .on(loginFx.fail, () => false);

$loginError.on(loginFx, () => null).on(loginFx.fail, (_, { error }) => error.message);

sample({
  clock: login,
  target: loginFx,
});

sample({
  clock: getUser,
  target: getUserDataFx,
});

sample({
  clock: loginFx.done,
  target: getUserDataFx,
});

sample({
  clock: getUserDataFx.done,
  target: getTags,
});
