import React from 'react';
import { unstable_ChipsSelect as ChipsSelect } from '@vkontakte/vkui';
import { TagType } from '../../types';

interface TagsSelectProps {
  valueTags: TagType[];
  options: TagType[];
  onChange: (selectedTags: { value: string; label: string }[]) => void;
  creatable: boolean;
  userId: string;
}

export const TagsSelect = ({ valueTags, options, onChange, creatable, userId }: TagsSelectProps) => {
  const getLabel = (tag: TagType) =>
    tag.name +
    (tag.parent ? ` (${tag.parent.name})` : '') +
    (tag.owner?._id && tag.owner._id !== userId ? ` (from ${tag.owner.name})` : '') +
    (tag.sharedWith?.length ? ` (to ${tag.sharedWith.map(el => el.name).join(', ')})` : '');
  const colorsChipsProps = {
    value: valueTags.map(tag => ({
      value: tag._id,
      label: getLabel(tag),
    })),
    onChange,
    options: options.map(tag => ({
      value: tag._id,
      label: getLabel(tag),
    })),
    placeholder: 'Не выбраны',
    creatable,
    creatableText: 'Создать новый тэг',
  };
  return <ChipsSelect {...colorsChipsProps} />;
};
