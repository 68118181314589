import { useStore } from 'effector-react';
import { $favorites, $favoritesLoading, getFavorites } from './favorites';

export const useFavorites = () => {
  return {
    favorites: useStore($favorites),
    loading: useStore($favoritesLoading),
    getFavorites,
  };
};
