import React, { ChangeEvent } from 'react';
import { Select, FormLayoutGroup, FormItem } from '@vkontakte/vkui';

const hours = new Array(24).fill(0).map((_, index) => index);
const minutes = new Array(60)
  .fill(0)
  .map((_, index) => index)
  .filter(min => min % 5 === 0);

export const TimeSelect = ({
  onChangeTime,
  defaultHourValue,
  defaultMinValue,
}: {
  onChangeTime: ({ name, value }: { name: string; value: string }) => void;
  defaultHourValue: number;
  defaultMinValue: number;
}) => {
  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChangeTime(event.target);
  };
  return (
    <div>
      <FormLayoutGroup mode="horizontal" style={{ padding: 0 }}>
        <FormItem top="Часы">
          <Select
            name="hour"
            options={hours.map(hour => ({ label: `${hour}`, value: hour }))}
            defaultValue={defaultHourValue}
            onChange={handleChange}
          />
        </FormItem>
        <FormItem top="Минуты">
          <Select
            name="minute"
            options={minutes.map(min => ({ label: `${min}`, value: min }))}
            defaultValue={defaultMinValue}
            onChange={handleChange}
          />
        </FormItem>
      </FormLayoutGroup>
    </div>
  );
  //}
};
