import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HelmetHeader, MyDiv } from '../../Components';
import { Group, Button, Title, Spacing, ScreenSpinner } from '@vkontakte/vkui';
import { ShortUser, TagType } from '../../types';
import { UserSelect } from './UserSelect';
import { updateTag } from '../../utils/api';
import { usePopout, useTags } from '../../models';

export const Tag = ({ tag, users }: { tag: TagType; users: ShortUser[] }) => {
  const navigate = useNavigate();
  const { setPopout, clearPopout } = usePopout();
  const { getTags } = useTags();
  const [selectedUsers, setSelectedUsers] = useState([...(tag.sharedWith || [])]);

  const handleSaveClick = () => {
    setPopout(<ScreenSpinner state="loading" />);
    updateTag(tag._id, { sharedWith: selectedUsers })
      .then(() => {
        getTags();
        navigate(-1);
      })
      .catch(() => {
        setPopout(<ScreenSpinner state="error" />);
        setTimeout(clearPopout, 1000);
      });
  };

  return (
    <>
      <HelmetHeader title={tag.name} hasBack />
      <Group>
        <MyDiv mode="horizontal" spacing={0}>
          <Title level="3">Выберите пользователей, с кем хотите поделиться этим тэгом</Title>
          <Spacing size={10} />
          <UserSelect
            options={users}
            value={selectedUsers}
            onChange={selected => setSelectedUsers(selected.map(el => ({ name: el.label, _id: el.value })))}
          />
        </MyDiv>
      </Group>
      <Group>
        <MyDiv mode="horizontal" spacing={0}>
          <Button stretched onClick={handleSaveClick}>
            Сохранить
          </Button>
        </MyDiv>
      </Group>
    </>
  );
};
