import React from 'react';
import { LinkContentItem } from '../../../types';
import {Input, Spacing, Subhead, Textarea} from '@vkontakte/vkui';

export const LinkEdit = ({ item }: { item: LinkContentItem }) => {
  return (
    <>
      <Subhead>Ссылка</Subhead>
      <Spacing size={5} />
      <Input
        defaultValue={item.name}
        onChange={event => (item.name = event.target.value)}
        placeholder="Текст для ссылки"
      />
      <Spacing size={8} />
      <Textarea
        autoFocus={item.value === ''}
        rows={1}
        name={item._id}
        defaultValue={item.value}
        onChange={event => (item.value = event.target.value.trim())}
        placeholder="Ссылка"
      />
    </>
  );
};
