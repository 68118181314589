import React, { useRef, useState } from 'react';
import {
  Spacing,
  Paragraph,
  Headline,
  Cell,
  List,
  Alert,
  SimpleCell,
  Checkbox,
  Title,
  classNames as cx,
  ActionSheetDefaultIosCloseItem,
  ActionSheet,
  ActionSheetItem,
  Button,
  ButtonGroup,
  Div,
  ScreenSpinner,
  IconButton,
  Link,
} from '@vkontakte/vkui';
import { ContentItem, ContentType } from '../../../types';
import { useItem, usePopout } from '../../../models';
import { Icon24Dismiss, Icon24MoreHorizontal } from '@vkontakte/icons';
import { cancelAction, getNegativeAction } from '../../../utils/actions';
import { reorderList } from '../../../utils/reorderList';
import { saveItem } from '../../../utils/api';
import { MyDiv } from '../../../Components';
import { copyObject } from '../../../utils/copyObject';
import { TextEdit } from './TextEdit';
import { DateEdit } from './DateEdit';
import { CheckboxEdit } from './CheckboxEdit';
import { formatMyDate } from '../../../utils/formateDate';
import { copyItemText, copyOneItemText } from '../../../utils/copyItemText';
import { matchPath, useLocation } from 'react-router-dom';
import { replaceLinks } from './replaceLinks';

import styles from './Content.module.css';
import { LinkEdit } from './LinkEdit';
import { fakeUpdateItem, removeContentItem, setContent } from '../../../models/item';

interface ContentProps {
  content?: ContentItem[];
  isEdit: boolean;
  selectedItem?: string;
}

interface HandleDeleteProps {
  id: string;
  title?: string;
  save?: boolean;
}

interface ContentEditElementProps {
  item: ContentItem;
}

const ContentEditElement = ({ item }: ContentEditElementProps) => {
  switch (item.type) {
    case ContentType.text:
      return <TextEdit item={item} />;
    case ContentType.link:
      return <LinkEdit item={item} />;
    case ContentType.date:
      return <DateEdit item={item} />;
    case ContentType.checkbox:
      return <CheckboxEdit item={item} />;
    default:
      return null;
  }
};

const ContentReadElement = ({
  item,
  handleDelete,
  handleSave,
}: {
  item: ContentItem;
  handleDelete: (props: HandleDeleteProps) => void;
  handleSave: () => Promise<unknown>;
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const cloneItem = useRef(copyObject(item));
  const { pathname } = useLocation();
  const ref = useRef(null);
  const { setPopout, clearPopout } = usePopout();

  const isSharedItem = matchPath('shared/:itemId', pathname);

  const onCancel = () => {
    Object.keys(cloneItem.current).forEach(key => {
      // @ts-ignore
      item[key] = cloneItem.current[key];
    });
    setIsEdit(false);
  };

  const has2CopyOptions =
    (item.type === ContentType.date && item.name) || (item.type === ContentType.text && item.name && item.value);

  const handleClick = () => {
    setPopout(
      <ActionSheet toggleRef={ref} onClose={clearPopout} iosCloseItem={<ActionSheetDefaultIosCloseItem />}>
        {item.type === ContentType.checkbox && (
          <ActionSheetItem
            autoClose
            onClick={() => {
              item.checked = !item.checked;
              fakeUpdateItem();
              handleSave().finally(() => setIsEdit(false));
            }}
          >
            {item.checked ? 'Отменить выполнение' : 'Выполнено'}
          </ActionSheetItem>
        )}
        <ActionSheetItem autoClose onClick={() => setIsEdit(e => !e)}>
          Редактировать
        </ActionSheetItem>
        {!has2CopyOptions && (
          <ActionSheetItem autoClose onClick={() => copyOneItemText(item)}>
            Копировать
          </ActionSheetItem>
        )}
        {has2CopyOptions && (
          <>
            <ActionSheetItem autoClose onClick={() => copyOneItemText(item)}>
              Копировать текст
            </ActionSheetItem>
            <ActionSheetItem autoClose onClick={() => copyItemText(item)}>
              Копировать полностью
            </ActionSheetItem>
          </>
        )}
        <ActionSheetItem
          autoClose
          mode="destructive"
          onClick={() => handleDelete({ id: item._id as string, title: item.name, save: true })}
        >
          Удалить запись
        </ActionSheetItem>
      </ActionSheet>,
    );
  };

  const afterEl = isSharedItem ? null : (
    <IconButton onClick={handleClick}>
      <Icon24MoreHorizontal className={styles.icon} />
    </IconButton>
  );

  const buttons = (
    <ButtonGroup stretched>
      <Button
        stretched
        onClick={() => {
          handleSave().finally(() => setIsEdit(false));
        }}
      >
        Сохранить
      </Button>
      <Button stretched onClick={onCancel} mode="secondary">
        Отменить
      </Button>
    </ButtonGroup>
  );

  if (item.type === ContentType.link) {
    return isEdit ? (
      <SimpleCell>
        <ContentEditElement item={item} />
        <MyDiv mode="vertical" spacing={0}>
          {buttons}
        </MyDiv>
      </SimpleCell>
    ) : (
      <SimpleCell getRootRef={ref} after={afterEl} hasActive={false} hasHover={false}>
        <Link href={item.value} target="_blank" rel="noopener noreferrer">
          {item.name || item.value}
        </Link>
      </SimpleCell>
    );
  }

  if (item.type === ContentType.text) {
    return isEdit ? (
      <SimpleCell>
        <ContentEditElement item={item} />
        <MyDiv mode="vertical" spacing={0}>
          {buttons}
        </MyDiv>
      </SimpleCell>
    ) : (
      <SimpleCell getRootRef={ref} after={afterEl} hasActive={false} hasHover={false}>
        <Title level="3">{item.name}</Title>
        <Spacing size={5} />
        <Paragraph dangerouslySetInnerHTML={{ __html: replaceLinks(item.value) }} />
      </SimpleCell>
    );
  }

  if (item.type === ContentType.checkbox) {
    return isEdit ? (
      <div>
        <ContentEditElement item={item} />
        <Div style={{ paddingTop: 0 }}>{buttons}</Div>
      </div>
    ) : (
      <SimpleCell
        getRootRef={ref}
        className={cx(styles.checkbox)}
        before={<Checkbox checked={item.checked} disabled />}
        after={afterEl}
        hasActive={false}
        hasHover={false}
      >
        <Headline level="2">{item.name}</Headline>
      </SimpleCell>
    );
  }

  if (item.type === ContentType.date) {
    return isEdit ? (
      <SimpleCell>
        <ContentEditElement item={item} />
        <MyDiv mode="vertical" spacing={0}>
          {buttons}
        </MyDiv>
      </SimpleCell>
    ) : (
      <SimpleCell getRootRef={ref} after={afterEl} hasActive={false} hasHover={false}>
        <Title level="3">{item.name}</Title>
        <Spacing size={5} />
        <div>{formatMyDate(item.date)}</div>
        {item.date.annual && <div>Ежегодное событие</div>}
      </SimpleCell>
    );
  }
  return null;
};

export const Content = ({ content, isEdit, selectedItem = '' }: ContentProps) => {
  const { setPopout, clearPopout } = usePopout();
  const { item: storeItem, getItem } = useItem();

  const handleSave = async () => {
    if (!storeItem) {
      return;
    }
    setPopout(<ScreenSpinner state="loading" />);
    return saveItem(storeItem)
      .then(() => setPopout(<ScreenSpinner state="done" />))
      .catch(() => {
        setPopout(<ScreenSpinner state="error" />);
        getItem(storeItem._id);
      })
      .finally(() => setTimeout(clearPopout, 1000));
  };

  if (!content) {
    return null;
  }

  const handleDelete = ({ id, title, save = false }: HandleDeleteProps) =>
    setPopout(
      <Alert
        actions={[
          cancelAction,
          getNegativeAction(() => {
            removeContentItem(id);
            if (save) {
              void handleSave();
            }
          }),
        ]}
        actionsLayout="horizontal"
        onClose={clearPopout}
        header="Удаление поля в документе"
        text={`Вы уверены, что хотите удалить поле${title ? ' ' + title : ''}?`}
      />,
    );

  return (
    <List>
      {content.map(item => {
        const id = item.key || item._id || '';
        if (isEdit) {
          return (
            <Cell
              key={id}
              className={styles.wrap}
              draggable
              onDragFinish={({ from, to }) => reorderList({ from, to }, content, setContent)}
              after={
                <Icon24Dismiss className={styles.removeBtn} onClick={() => handleDelete({ id, title: item.name })} />
              }
            >
              <ContentEditElement item={item} />
            </Cell>
          );
        }
        return (
          <div className={cx(styles.wrap, selectedItem === item._id && styles.selected)} key={id}>
            <ContentReadElement item={item} handleDelete={handleDelete} handleSave={handleSave} />
          </div>
        );
      })}
    </List>
  );
};
