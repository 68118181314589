import { TagType } from './tag';

export interface ItemType {
  _id: string;
  owner: {
    _id: string;
    name: string;
  };
  shared: boolean;
  deleted: boolean;
  favorite: boolean;
  title: string;
  content: ContentItem[];
  tags: TagType[];
  shadowTags: TagType[];
  createdAt: string;
  updatedAt: string;
  editedBy: {
    _id: string;
    name: string;
  };
}

export enum ContentType {
  text = 'text',
  link = 'link',
  checkbox = 'checkbox',
  date = 'date',
}

interface BaseContentItem {
  type: ContentType;
  name: string;
  _id?: string;
  key?: string;
}

export interface TextContentItem extends BaseContentItem {
  value: string;
  type: ContentType.text;
}

export interface LinkContentItem extends BaseContentItem {
  value: string;
  type: ContentType.link;
}

export interface CheckboxContentItem extends BaseContentItem {
  checked: boolean;
  type: ContentType.checkbox;
}

export interface DateType {
  year: number;
  month: number;
  day: number;
  hour?: number;
  minute?: number;
  annual: boolean;
}

export type DateObj = Pick<DateType, 'year' | 'month' | 'day'>;

export interface DateContentItem extends BaseContentItem {
  date: DateType;
  type: ContentType.date;
}

export type ContentItem = TextContentItem | CheckboxContentItem | DateContentItem | LinkContentItem;
