import React from 'react';
import { Helmet } from 'react-helmet';
import { PanelHeader, PanelHeaderProps, PanelHeaderBack, PanelHeaderButton } from '@vkontakte/vkui';
import { Icon28DoorArrowRightOutline, Icon28ComputerOutline, Icon28AddOutline } from '@vkontakte/icons';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { logout } from '../../models/logout';
import { useUser } from '../../models';
import styles from './Header.module.css';

interface HeaderProps extends PanelHeaderProps {
  hasAfter?: boolean;
  hasBack?: boolean;
  backAction?: () => void;
}

const homeUrl = '/';
const newItemUrl = '/item/new';

export const HelmetHeader = ({ title, hasAfter = true, hasBack = false, backAction, ...rest }: HeaderProps) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const location = useLocation();
  const goBack = () => {
    navigate(-1);
    backAction?.();
  };
  const isHome = location.pathname === homeUrl;
  const isNewItemUrl = location.pathname === newItemUrl;

  const getAfter = () => {
    switch (true) {
      case !hasAfter:
        return null;
      case isHome:
        return (
          <PanelHeaderButton>
            <Icon28DoorArrowRightOutline onClick={() => logout()} />
          </PanelHeaderButton>
        );
      case !isHome:
      default:
        return (
          <div className={styles.after}>
            {!!user && !isNewItemUrl && (
              <PanelHeaderButton>
                <Link to={newItemUrl}>
                  <Icon28AddOutline />
                </Link>
              </PanelHeaderButton>
            )}
            <PanelHeaderButton>
              <Link to={homeUrl}>
                <Icon28ComputerOutline />
              </Link>
            </PanelHeaderButton>
          </div>
        );
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <PanelHeader before={hasBack ? <PanelHeaderBack onClick={goBack} /> : null} after={getAfter()} {...rest}>
        {title}
      </PanelHeader>
    </>
  );
};
