import { ItemType } from '../../types';
import { rootDomain } from '../root';
import { getFavoriteItems } from '../../utils/api';
import { sample } from 'effector';

const favoritesDomain = rootDomain.createDomain('favorites');

export const $favorites = favoritesDomain.createStore<ItemType[]>([]);
export const $favoritesLoading = favoritesDomain.createStore(true);

export const getFavorites = favoritesDomain.createEvent();
export const getFavoritesFx = favoritesDomain.createEffect(getFavoriteItems);

$favorites.on(getFavoritesFx.doneData, (_, favorites) => favorites.data);

$favoritesLoading.on(getFavoritesFx, () => true).on(getFavoritesFx.finally, () => false);

sample({
  clock: getFavorites,
  target: getFavoritesFx,
});
