import React from 'react';
import { CheckboxContentItem } from '../../../types';
import { Checkbox, SimpleCell, Spacing, Subhead, Textarea } from '@vkontakte/vkui';
import styles from './Content.module.css';

export const CheckboxEdit = ({ item }: { item: CheckboxContentItem }) => {
  return (
    <>
      <Subhead>Чекбокс</Subhead>
      <Spacing size={5} />
      <SimpleCell
        className={styles.checkbox}
        before={<Checkbox defaultChecked={item.checked} onChange={event => (item.checked = event.target.checked)} />}
      >
        <Textarea
          rows={1}
          defaultValue={item.name}
          autoFocus={item.name === ''}
          onChange={event => (item.name = event.target.value)}
          placeholder="Название"
        />
      </SimpleCell>
    </>
  );
};
