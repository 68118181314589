import { ItemType } from '../types';
import React, { ReactNode, SyntheticEvent } from 'react';
import { copyTextToClipboard } from '../utils/copyTextToClipboard';
import { Avatar, Snackbar } from '@vkontakte/vkui';
import { Icon16Done } from '@vkontakte/icons';
import { shareItem } from '../utils/api';
import { urlRusLat } from '../utils/rusToLat';

export const useShareLink = (item: ItemType | null) => {
  const [snackbar, setSnackbar] = React.useState<ReactNode>(null);

  const latTitle = urlRusLat(item?.title).slice(0, 50);

  const linkToShared = `${window.location.origin}/shared/${item?._id}?d=${latTitle}`;
  const copySharedLink = (event?: SyntheticEvent) => {
    event?.stopPropagation();
    copyTextToClipboard(linkToShared).then(() => {
      setSnackbar(
        <Snackbar
          onClose={() => setSnackbar(null)}
          before={
            <Avatar size={24}>
              <Icon16Done width={14} height={14} />
            </Avatar>
          }
        >
          Ссылка скопирована
        </Snackbar>,
      );
    });
  };

  const handleShare = async (id?: string) => {
    if (!item) {
      return;
    }
    return shareItem(id || item._id, !item.shared).then(() => {
      copySharedLink();
    });
  };

  return {
    snackbar,
    copySharedLink,
    linkToShared,
    shareItem: handleShare,
  };
};
