import { DateType } from '../types';

export const formatDate = (time: Date | string) => {
  if (!Boolean(time)) {
    return '';
  }

  if (typeof time === 'string') {
    time = new Date(time);
  }

  const year = time.getFullYear();
  const month = time.getMonth() + 1 > 9 ? time.getMonth() + 1 : `0${time.getMonth() + 1}`;
  const date = time.getDate() > 9 ? time.getDate() : `0${time.getDate()}`;
  return `${year}-${month}-${date}`;
};

const format = (num: number) => `${num}`.padStart(2, '0');
export const formatMyDate = ({ day, year, hour, month, minute }: Omit<DateType, 'annual'>) => {
  // let myDate = `${year}-${format(month)}-${format(day)}`;
  let myDate = `${format(day)}.${format(month)}.${year}`;

  if (hour !== undefined && minute !== undefined) {
    myDate += ` ${format(hour)}:${format(minute)}`;
  }

  return myDate;
};

export const parseFormatDate = (dateStr?: string) => {
  if (!dateStr) {
    return;
  }
  const [day, month, year] = dateStr.split('.');
  return new Date(Number(year), Number(month) - 1, Number(day));
};
