import { rootDomain } from '../root';
import { TagType } from '../../types';

const searchDomain = rootDomain.createDomain('search');

export const $searchTags = searchDomain.createStore<TagType[]>([]);
export const $searchText = searchDomain.createStore('');
export const $searchPage = searchDomain.createStore(1);

export const clearSearchData = searchDomain.createEvent('on_destroy');
searchDomain.onCreateStore(store => store.reset([clearSearchData]));

export const setSearchText = searchDomain.createEvent<string>();
export const getSearchTextFunc = searchDomain.createEvent<(str: string) => void>();
export const setSearchTags = searchDomain.createEvent<TagType[]>();
export const setSearchPage = searchDomain.createEvent<number>();

$searchTags.on(setSearchTags, (_, tags) => tags);
$searchText
  .on(setSearchText, (_, text) => text)
  .on(getSearchTextFunc, (store, getFunc) => {
    getFunc(store);
    return store;
  });
$searchPage
  .on(setSearchPage, (_, page) => page)
  .on(setSearchTags, _ => 1)
  .on(setSearchText, _ => 1);
