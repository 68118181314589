import React from 'react';
import { unstable_ChipsSelect as ChipsSelect } from '@vkontakte/vkui';
import { ShortUser, TagType } from '../../types';

interface UserSelectProps {
  value: ShortUser[];
  options: ShortUser[];
  onChange: (selectedTags: { value: string; label: string }[]) => void;
}

export const UserSelect = ({ value, options, onChange }: UserSelectProps) => {
  const colorsChipsProps = {
    value: value.map(user => ({
      value: user._id,
      label: user.name,
    })),
    onChange,
    options: options.map(user => ({
      value: user._id,
      label: user.name,
    })),
    placeholder: 'Не выбраны',
    creatable: false,
  };
  return <ChipsSelect {...colorsChipsProps} />;
};
