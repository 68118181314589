import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HelmetHeader, TagsSelect } from '../../Components';
import {
  Group,
  FormItem,
  Input,
  Pagination,
  Div,
  Spinner,
  SimpleCell,
  IconButton,
  ActionSheet,
  ActionSheetDefaultIosCloseItem,
  ActionSheetItem,
  Alert,
  ScreenSpinner,
  Title,
} from '@vkontakte/vkui';
import { useTags, useSearch, useUser, usePopout } from '../../models';
import { ItemType } from '../../types';
import { isT } from '../../utils/typeGuard';
import { debounce } from '@vkontakte/vkui/dist/lib/utils';
import { deleteItem, getItems, copyItem, copyItemStructure } from '../../utils/api';
import styles from './Items.module.css';
import { useNavigate } from 'react-router-dom';
import { Icon16Clear, Icon24MoreHorizontal, Icon24ShareExternal } from '@vkontakte/icons';
import { formatDate } from '../../utils/formateDate';
import { cancelAction, getNegativeAction } from '../../utils/actions';
import { useShareLink } from '../../hooks/useShareLink';
import { clearSearchData, getSearchTextFunc, setSearchPage, setSearchTags, setSearchText } from '../../models/search';

const getItemSubtitle = (item: ItemType, userId: string): string => {
  const res = [];
  if (item.editedBy._id !== item.owner._id) {
    res.push(`Изменено ${item.editedBy.name}`);
  }

  res.push(formatDate(item.updatedAt));

  // if (userId !== item.owner._id) {
  //   res.push(`Владелец ${item.owner.name}`);
  // }

  return res.join(', ');
};

export const ItemsOne = ({ item, getItems }: { item: ItemType; getItems: () => void }) => {
  const ref = useRef(null);
  const navigation = useNavigate();
  const { user } = useUser();
  const { setPopout, clearPopout } = usePopout();
  const { snackbar, copySharedLink, shareItem } = useShareLink(item);

  const onDelete = () => onChange(deleteItem);
  const onStructureCopy = () => onChange(copyItemStructure);
  const onCopy = () => onChange(copyItem);
  const onShared = () => onChange(shareItem);

  const onChange = (func: (id: string) => Promise<any>) => {
    func(item._id)
      .then(() => {
        setPopout(<ScreenSpinner state="done" />);
        getItems();
      })
      .catch(() => setPopout(<ScreenSpinner state="error" />))
      .finally(() => setTimeout(clearPopout, 1000));
  };

  const handleDelete = () =>
    setPopout(
      <Alert
        actions={[cancelAction, getNegativeAction(() => onDelete())]}
        actionsLayout="horizontal"
        onClose={clearPopout}
        header="Удаление документа"
        text="Вы уверены, что хотите удалить документ?"
      />,
    );

  const handleCopy = () =>
    setPopout(
      <Alert
        actions={[
          cancelAction,
          {
            title: 'Полностью',
            autoClose: true,
            mode: 'default',
            action: () => onCopy(),
          },
          {
            title: 'Структуру',
            autoClose: true,
            mode: 'default',
            action: () => onStructureCopy(),
          },
        ]}
        actionsLayout="horizontal"
        onClose={clearPopout}
        header="Копирование документа"
        text="Скопировать документ полностью или только его структуру?"
      />,
    );

  const handleShareLink = () => {
    const title = item.shared ? 'Прекратить делиться' : 'Поделиться';
    const text = item.shared ? null : 'Сделать документ доступным всем по ссылке?';
    return setPopout(
      <Alert
        actions={[
          cancelAction,
          {
            title,
            autoClose: true,
            mode: 'default',
            action: () => onShared(),
          },
        ]}
        actionsLayout="horizontal"
        onClose={clearPopout}
        header={title}
        text={text}
      />,
    );
  };

  const handleClick = () => navigation(`/item/${item._id}`);
  const handleEdit = () => navigation(`/item/${item._id}?edit=1`, {});

  const handleRightClick = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setPopout(
      <ActionSheet toggleRef={ref} onClose={clearPopout} iosCloseItem={<ActionSheetDefaultIosCloseItem />}>
        <ActionSheetItem>
          <Title level="2" className={styles.title}>
            {item.title}
          </Title>
        </ActionSheetItem>
        <ActionSheetItem autoClose onClick={handleClick}>
          Открыть
        </ActionSheetItem>
        <ActionSheetItem autoClose onClick={handleEdit}>
          Редактировать
        </ActionSheetItem>
        {item.shared && (
          <ActionSheetItem autoClose onClick={copySharedLink}>
            Скопировать ссылку
          </ActionSheetItem>
        )}
        {user._id === item.owner._id && (
          <ActionSheetItem autoClose onClick={handleShareLink}>
            {item.shared ? 'Перестать делиться ссылкой' : 'Поделиться по ссылке'}
          </ActionSheetItem>
        )}
        <ActionSheetItem autoClose onClick={handleCopy}>
          Создать копию документа
        </ActionSheetItem>
        <ActionSheetItem autoClose mode="destructive" onClick={handleDelete}>
          Удалить
        </ActionSheetItem>
      </ActionSheet>,
    );
  };

  return (
    <>
      {snackbar}
      <SimpleCell
        onClick={handleClick}
        getRootRef={ref}
        expandable
        multiline
        subtitle={getItemSubtitle(item, user._id)}
        after={
          <div className={styles.after}>
            {item.shared && (
              <IconButton onClick={copySharedLink}>
                <Icon24ShareExternal />
              </IconButton>
            )}
            <IconButton onClick={handleRightClick}>
              <Icon24MoreHorizontal className={styles.icon} />
            </IconButton>
          </div>
        }
      >
        {user._id !== item.owner._id ? `${item.owner.name} - ` : ''}
        {item.title}
      </SimpleCell>
    </>
  );
};

export const Items = () => {
  const [items, setItems] = useState<ItemType[]>([]);
  const [loading, setLoading] = useState(false);
  const firstRender = useRef(true);
  const [totalPages, setTotalPages] = useState(0);
  const [counter, setCounter] = useState(0);
  const { tags: _tags, sharedTags } = useTags();
  const { text, searchTags, searchPage } = useSearch();
  const { user } = useUser();
  const tags = [..._tags, ...sharedTags];

  const handleChange = React.useCallback((page: number) => {
    setSearchPage(page);
    increaseCounter();
  }, []);

  const increaseCounter = () => setCounter(c => c + 1);

  const handleTag = (selectedTags: { value: string; label: string }[]) => {
    const resultTags = selectedTags
      .map(tag => {
        return tags.find(el => el._id === tag.value);
      })
      .filter(isT);

    setSearchTags(resultTags);
  };

  const fetchItems = useCallback((text: string, tagIDs: string[], page: number) => {
    const req = { text, tags: tagIDs, page };
    setLoading(true);
    getItems(req).then(({ data }) => {
      getSearchTextFunc(val => {
        if (val === text) {
          setItems(data.items);
          setTotalPages(Math.ceil(data.totalCount / data.itemOnPage));
          setLoading(false);
        }
      });
    });
  }, []);

  const debouncedSearch = React.useCallback(
    debounce((text, tagIDs, page) => {
      if (firstRender.current) {
        firstRender.current = false;
        return;
      }
      fetchItems(text, tagIDs, page);
    }, 500),
    [debounce],
  );

  useEffect(() => {
    debouncedSearch(
      text,
      searchTags.map(tag => tag._id),
      searchPage,
    );
  }, [text, searchTags]);

  useEffect(() => {
    fetchItems(
      text,
      searchTags.map(tag => tag._id),
      searchPage,
    );
  }, [counter]);

  return (
    <>
      <HelmetHeader title="Search" hasBack backAction={clearSearchData} />
      <Group>
        <FormItem top="Выберите тэги">
          <TagsSelect valueTags={searchTags} options={tags} onChange={handleTag} creatable={false} userId={user._id} />
        </FormItem>
        <FormItem top="Строка для поиска">
          <Input
            value={text}
            onChange={event => setSearchText(event.target.value)}
            after={
              text.length > 0 ? (
                <IconButton hoverMode="opacity" aria-label="Очистить поле" onClick={() => setSearchText('')}>
                  <Icon16Clear />
                </IconButton>
              ) : null
            }
          />
        </FormItem>
      </Group>
      <Group>
        {loading ? (
          <Spinner />
        ) : (
          items.map(item => {
            return <ItemsOne key={item._id} item={item} getItems={increaseCounter} />;
          })
        )}
      </Group>
      {!!items.length && totalPages > 1 && !loading && (
        <Group>
          <Div>
            <Pagination
              currentPage={searchPage}
              siblingCount={1}
              boundaryCount={1}
              totalPages={totalPages}
              onChange={handleChange}
              className={styles.center}
            />
          </Div>
        </Group>
      )}
    </>
  );
};
