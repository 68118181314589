import { POST, sendRequest, GET, DELETE, PUT } from './sendRequest';
import { Login, User, TagType, SharedTag, ItemType, ShortUser, EventType, DateObj } from '../types';

export const login = (params: Login): Promise<{ data: boolean }> =>
  sendRequest({
    url: '/api/auth/login',
    method: POST,
    params,
  });

export const getUserData = (): Promise<{ data: User }> =>
  sendRequest({
    url: '/api/auth/userData',
    method: GET,
  });

export const getTags = (): Promise<{ data: { tags: TagType[]; sharedTags: SharedTag[] } }> =>
  sendRequest({
    url: '/api/tags',
    method: GET,
  });

export const updateTag = (id: string, params: { sharedWith: ShortUser[] }): Promise<{ data: boolean }> =>
  sendRequest({
    url: `/api/tags/${id}`,
    method: POST,
    params,
  });

export const getShortUsers = (): Promise<{ data: ShortUser[] }> =>
  sendRequest({
    url: '/api/users/short',
    method: GET,
  });

export const getItem = (id: string): Promise<{ data: ItemType }> =>
  sendRequest({
    url: `/api/item/${id}`,
    method: GET,
  });

export const getFavoriteItems = (): Promise<{ data: ItemType[] }> =>
  sendRequest({
    url: `/api/items/favorites`,
    method: GET,
  });

export const deleteItem = (id: string) =>
  sendRequest({
    url: `/api/item/${id}`,
    method: DELETE,
  });

export const copyItem = (id: string) =>
  sendRequest({
    url: `/api/item/copy/${id}`,
    method: PUT,
  });

export const copyItemStructure = (id: string) =>
  sendRequest({
    url: `/api/item/copyStructure/${id}`,
    method: PUT,
  });

export const shareItem = (id: string, share: boolean): Promise<{ data: boolean }> =>
  sendRequest({
    url: `/api/item/share/${id}`,
    method: POST,
    params: { share },
  });

export const favoriteItem = (id: string, favorite: boolean): Promise<{ data: boolean }> =>
  sendRequest({
    url: `/api/item/favorite/${id}`,
    method: POST,
    params: { favorite },
  });

export const getSharedItem = (id: string): Promise<{ data: { item: ItemType; hasPerm: boolean } }> =>
  sendRequest({
    url: `/api/item/shared/${id}`,
  });

export const saveItem = async (item: ItemType | null): Promise<{ data: string | boolean }> =>
  item
    ? sendRequest({
        url: `/api/item`,
        method: POST,
        params: item,
      })
    : Promise.reject('empty Item');

export const getItems = ({
  text,
  tags,
  page,
}: {
  text: string;
  tags: string[];
  page: number;
}): Promise<{
  data: {
    items: ItemType[];
    totalCount: number;
    page: number;
    itemOnPage: number;
  };
}> =>
  sendRequest({
    url: `/api/items`,
    method: POST,
    params: { text, tags, page },
  });

export const getEventsByDates = (dateFrom: DateObj, dateTo: DateObj): Promise<{ data: EventType[] }> => {
  const { day: dayFrom, year: yearFrom, month: monthFrom } = dateFrom;
  const { day: dayTo, year: yearTo, month: monthTo } = dateTo;
  return sendRequest({
    url: `/api/events/fromTo?dayFrom=${dayFrom}&monthFrom=${monthFrom}&yearFrom=${yearFrom}&dayTo=${dayTo}&monthTo=${monthTo}&yearTo=${yearTo}`,
    method: GET,
  });
};
