import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { useStore } from 'effector-react';
import { RouterProvider } from 'react-router-dom';
import {
  AdaptivityProvider,
  AppRoot,
  ConfigProvider,
  Platform,
  SizeType,
  Spinner,
  SplitCol,
  SplitLayout,
  WebviewType,
} from '@vkontakte/vkui';
import { usePopout } from './models';
import { router, unauthorizedRouter } from './routing';
import { getCustomPlatform } from './utils/getCustomPlatform';
import { $user, $userInitialLoading, getUser } from './models/user';

import '@vkontakte/vkui/dist/vkui.css';
import './index.css';

export const Root = () => {
  const user = useStore($user);
  const initialLoading = useStore($userInitialLoading);
  const { popout } = usePopout();
  const platform = getCustomPlatform();

  useEffect(getUser, []);

  return initialLoading ? (
    <Spinner size="large" />
  ) : user ? (
    <SplitLayout popout={popout} style={{ justifyContent: 'center' }}>
      <SplitCol maxWidth={768} minWidth={platform === Platform.VKCOM ? 650 : undefined}>
        <RouterProvider router={router} />
      </SplitCol>
    </SplitLayout>
  ) : (
    <RouterProvider router={unauthorizedRouter} />
  );
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ConfigProvider webviewType={WebviewType.INTERNAL}>
    <AdaptivityProvider sizeY={SizeType.COMPACT}>
      <AppRoot>
        <Root />
      </AppRoot>
    </AdaptivityProvider>
  </ConfigProvider>,
);
