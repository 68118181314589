export interface User {
  _id: string;
  email: string;
  login: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  parts?: UserParts[];
  // tags: TagType[];
  // sharedTags: [
  //   {
  //     _id: string;
  //     owner: {
  //       _id: string;
  //       name: string;
  //     };
  //     name: string;
  //   },
  // ];
}

export type Login = {
  login: string;
  password: string;
};

export interface ShortUser {
  _id: string;
  name: string;
}

export enum UserParts {
  gitHelper = 'gitHelper',
}
