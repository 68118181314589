import { TagType, SharedTag } from '../../types';
import { rootDomain } from '../root';
import { getTags as getTagsAPI } from '../../utils/api';
import { sample } from 'effector';
import { objCompareCreator } from '../../utils/compareCreator';

const tagsDomain = rootDomain.createDomain('tags');

export const $tags = tagsDomain.createStore<TagType[]>([]);
export const $sharedTags = tagsDomain.createStore<SharedTag[]>([]);

export const $tagsLoading = tagsDomain.createStore(true);

export const getTags = tagsDomain.createEvent();

export const getTagsFx = tagsDomain.createEffect(getTagsAPI);

$tags.on(getTags, () => []).on(getTagsFx.doneData, (_, tags) => tags.data.tags.sort(objCompareCreator('name')));
$sharedTags
  .on(getTags, () => [])
  .on(getTagsFx.doneData, (_, tags) => tags.data.sharedTags.sort(objCompareCreator('name')));

$tagsLoading.on(getTags, () => true).on(getTagsFx.finally, () => false);

sample({
  clock: getTags,
  target: getTagsFx,
});
