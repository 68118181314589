export const cancelAction = {
  title: 'Отмена',
  autoClose: true,
  mode: 'cancel',
} as const;

export const getNegativeAction = (action: any) =>
  ({
    title: 'Удалить',
    autoClose: true,
    mode: 'destructive',
    action,
  } as const);
