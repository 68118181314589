import { useStore } from 'effector-react';
import { $searchText, $searchTags, $searchPage } from './search';

export const useSearch = () => {
  return {
    text: useStore($searchText),
    searchTags: useStore($searchTags),
    searchPage: useStore($searchPage),
  };
};
