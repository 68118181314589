import { HelmetHeader } from '../../Components';
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import {
  Checkbox,
  FormItem,
  FormLayoutGroup,
  Group,
  Header,
  IconButton,
  Input,
  Radio,
  RadioGroup,
} from '@vkontakte/vkui';
import { DOMAIN_TYPES, Domains, DOMAINS, VKUI_APPS } from './helpers';
import { CopyCell } from './CopyCell';
import { GitCommands } from './types';
import { Icon20AddSquareOutline } from '@vkontakte/icons';

const gitAdd = 'git add *';
const gitReset = 'git reset --soft HEAD~1';
const gitPull = 'git checkout master && git pull origin master && git pull';
const gitClear = 'git reset && git clean -f -d && git checkout . && git status';
const CUSTOM_DOMAIN_NAME = 'customDomainName';

export const GitHelper = () => {
  const [user, setUser] = useState('spukhalenko');
  const [task, setTask] = useState('COM-');
  const [branchMiddlePart, setBranchMiddlePart] = useState('');
  const [branch, setBranch] = useState('');
  const [customDomainName, setCustomDomainName] = useState('');
  const [domainSpace, setDomainSpace] = useState<Domains>(Domains.vkcom);
  const [domain, setDomain] = useState(DOMAINS[domainSpace][0]);
  const [vkuiApp, setVkuiApp] = useState(VKUI_APPS[0]);
  const [selectedCommands, setSelectedCommands] = useState<GitCommands[]>([]);

  const [createNewBranch, setCreateNewBranch] = useState(true);
  const [forcePush, setForcePush] = useState(true);
  const [noVerify, setNoVerify] = useState(false);
  const [commitMessageText, setCommitMessageText] = useState('');

  useEffect(() => {
    setBranch(`${user}/${branchMiddlePart}/${task}`);
    setCommitMessageText(`${task} ${branchMiddlePart}`.trim());
  }, [user, task, branchMiddlePart]);

  useEffect(() => {
    const storageData = JSON.parse(localStorage.getItem('data') || '{}');
    const taskData = storageData[task];

    if (taskData) {
      setUser(taskData.user);
      setTask(taskData.task);
      setBranchMiddlePart(taskData.branchMiddlePart);
      setDomainSpace(taskData.domainSpace);
      setDomain(taskData.domain);
      setVkuiApp(taskData.vkuiApp);
      setCreateNewBranch(taskData.createNewBranch);
      setForcePush(taskData.forcePush);
      setCommitMessageText(taskData.commitMessageText);
      setCustomDomainName(taskData.customDomainName || '');
      setSelectedCommands(taskData.selectedCommands || []);
    }
  }, [task]);

  const onCommandCheck = (command: GitCommands, checked: boolean) => {
    setSelectedCommands(commands => (checked ? [...commands, command] : commands.filter(el => el !== command)));
  };

  const handleChangeBranch: ChangeEventHandler<HTMLInputElement> = event => {
    const newBranchValue = event.target.value;
    const branchParts = newBranchValue.split('/');
    if (branchParts.length !== 3) {
      return;
    }

    const [_user, _branchMiddlePart, _task] = branchParts;
    setBranch(newBranchValue);
    setUser(_user);
    setTask(_task);
    setBranchMiddlePart(_branchMiddlePart);
  };

  const onDomainClick: ChangeEventHandler<HTMLInputElement> = event => {
    const newDomainSpace = event.target.value as Domains;
    setDomainSpace(newDomainSpace);
    setDomain(DOMAINS[newDomainSpace][0]);
    setCustomDomainName('');
  };

  const saveCustomDomain = () => {
    const localDomains = JSON.parse(localStorage.getItem(Domains.vkcom) || '[]');
    if (!localDomains.includes(customDomainName)) {
      localStorage.setItem(domainSpace, JSON.stringify([...localDomains, customDomainName]));
    }
  };

  const onCopy = () => {
    const storageData = JSON.parse(localStorage.getItem('data') || '{}');
    storageData[task] = {
      user,
      task,
      branchMiddlePart,
      domainSpace,
      domain,
      vkuiApp,
      createNewBranch,
      forcePush,
      customDomainName,
      commitMessageText,
      selectedCommands,
    };
    localStorage.setItem('data', JSON.stringify(storageData));
  };

  const selectedDomain = domain === CUSTOM_DOMAIN_NAME ? customDomainName : domain;
  const gitVersion = localStorage.getItem('gitVersion') || '/usr/bin/git.2.20.1';

  const createBranchCommand = 'git checkout ' + (createNewBranch ? '-b ' : '') + branch;
  const gitCommit = `git commit -m "${commitMessageText}"${noVerify ? ' --no-verify' : ''}`;
  const gitPush =
    (noVerify ? gitVersion : 'git') +
    ' push origin ' +
    (forcePush ? '-f ' : '') +
    branch +
    (noVerify ? ' --no-verify' : '');
  const gitDeployVKCOM = `vk domain ${selectedDomain} ${branch}`;
  const gitDeployVKUI = `vk vkui_domain ${vkuiApp} ${selectedDomain} ${branch}`;
  const gitDeploy = domainSpace === Domains.vkcom ? gitDeployVKCOM : gitDeployVKUI;

  const selectedAdd = selectedCommands.includes(GitCommands.add);
  const selectedCommit = selectedCommands.includes(GitCommands.commit);
  const selectedPush = selectedCommands.includes(GitCommands.push);
  const selectedDeploy = selectedCommands.includes(GitCommands.deploy);

  const gitCommands = [
    selectedAdd ? gitAdd : null,
    selectedCommit ? gitCommit : null,
    selectedPush ? gitPush : null,
    selectedDeploy ? gitDeploy : null,
  ]
    .filter(Boolean)
    .join(' && ')
    .replace(`${gitAdd} &&`, `${gitAdd} ;`);

  return (
    <>
      <HelmetHeader title="Git helper" hasBack hasAfter={false} />
      <Group>
        <FormItem top="Username">
          <Input value={user} onChange={event => setUser(event.target.value)} />
        </FormItem>
        <FormLayoutGroup mode="horizontal">
          <FormItem top="Task">
            <Input value={task} onChange={event => setTask(event.target.value)} />
          </FormItem>
          <FormItem top="Branch middle part">
            <Input value={branchMiddlePart} onChange={event => setBranchMiddlePart(event.target.value)} />
          </FormItem>
        </FormLayoutGroup>
        <FormItem top="Result branch">
          <Input value={branch} onChange={handleChangeBranch} />
        </FormItem>
      </Group>

      <Group>
        <Header mode="primary">Settings</Header>
        <FormItem>
          <Checkbox checked={createNewBranch} onChange={event => setCreateNewBranch(event.target.checked)}>
            Create new branch
          </Checkbox>
        </FormItem>
        <FormItem top="Commit message">
          <Input value={commitMessageText} onChange={event => setCommitMessageText(event.target.value)} />
        </FormItem>
        <FormItem>
          <Checkbox checked={forcePush} onChange={event => setForcePush(event.target.checked)}>
            Force push
          </Checkbox>
        </FormItem>
        <FormItem>
          <Checkbox checked={noVerify} onChange={event => setNoVerify(event.target.checked)}>
            No verify
          </Checkbox>
        </FormItem>
        <FormItem top="Domain space">
          <RadioGroup>
            {DOMAIN_TYPES.map(type => (
              <Radio key={type} name="domainSpace" value={type} checked={domainSpace === type} onChange={onDomainClick}>
                {type}
              </Radio>
            ))}
          </RadioGroup>
        </FormItem>
        <FormItem top="Domain">
          <RadioGroup>
            {[...DOMAINS[domainSpace], ...JSON.parse(localStorage.getItem(domainSpace) || '[]')].map(
              (_domain: string) => (
                <Radio
                  key={_domain}
                  name="domain"
                  value={_domain}
                  checked={domain === _domain}
                  onChange={() => setDomain(_domain)}
                >
                  {_domain}
                </Radio>
              ),
            )}
            <Radio
              name="domain"
              value={CUSTOM_DOMAIN_NAME}
              checked={domain === CUSTOM_DOMAIN_NAME}
              onChange={() => setDomain(CUSTOM_DOMAIN_NAME)}
              titleAfter={
                <IconButton hasActive={false} hoverMode="" onClick={saveCustomDomain}>
                  <Icon20AddSquareOutline />
                </IconButton>
              }
            >
              <Input value={customDomainName} onChange={event => setCustomDomainName(event.target.value)} />
            </Radio>
          </RadioGroup>
        </FormItem>
        {domainSpace === Domains.vkui && (
          <FormItem top="VKUI app">
            <RadioGroup>
              {VKUI_APPS.map((app: string) => (
                <Radio key={app} name="vkui_app" value={app} checked={vkuiApp === app} onChange={() => setVkuiApp(app)}>
                  {app}
                </Radio>
              ))}
            </RadioGroup>
          </FormItem>
        )}
      </Group>

      <Group>
        <Header mode="primary">Commands</Header>
        <CopyCell onCopy={onCopy} text={gitPull} />
        <CopyCell onCopy={onCopy} text={gitClear} />
        <CopyCell onCopy={onCopy} text={gitReset} />
        <CopyCell onCopy={onCopy} text={createBranchCommand} />
        <CopyCell
          selectable
          command={GitCommands.add}
          checked={selectedAdd}
          onCopy={onCopy}
          text={gitAdd}
          onCommandCheck={onCommandCheck}
        />
        <CopyCell
          selectable
          command={GitCommands.commit}
          checked={selectedCommit}
          onCopy={onCopy}
          text={gitCommit}
          onCommandCheck={onCommandCheck}
        />
        <CopyCell
          selectable
          command={GitCommands.push}
          checked={selectedPush}
          onCopy={onCopy}
          text={gitPush}
          onCommandCheck={onCommandCheck}
        />
        <CopyCell
          selectable
          command={GitCommands.deploy}
          checked={selectedDeploy}
          onCopy={onCopy}
          text={gitDeploy}
          onCommandCheck={onCommandCheck}
        />
        {gitCommands.length > 0 && <CopyCell onCopy={onCopy} text={gitCommands} />}
      </Group>
    </>
  );
};
