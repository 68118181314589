import { useStore } from 'effector-react';
import { $item, $itemLoading, getItem, $fetchError } from './item';

export const useItem = () => {
  return {
    item: useStore($item),
    error: useStore($fetchError),
    loading: useStore($itemLoading),
    getItem,
  };
};
