import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTags } from '../../models';
import { HelmetHeader } from '../../Components';
import { Tag } from './Tag';
import { FormStatus, Spinner } from '@vkontakte/vkui';
import { ShortUser } from '../../types';
import { getShortUsers } from '../../utils/api';

export const TagWrap = () => {
  const { tagId } = useParams();
  const { tags } = useTags();
  const tag = tags.find(tag => tag._id === tagId);
  const [users, setUsers] = useState<ShortUser[] | null>(null);

  useEffect(() => {
    getShortUsers()
      .then(res => setUsers(res.data))
      .catch(console.error);
  }, []);

  if (tags.length === 0 || !users) {
    return (
      <>
        <HelmetHeader title="Загрузка..." />
        <Spinner size="large" />
      </>
    );
  }

  if (!tag) {
    return (
      <>
        <HelmetHeader title="Ошибка" hasBack />
        <FormStatus mode="error">Некорректный tagId {tagId}</FormStatus>
      </>
    );
  }

  return <Tag tag={tag} users={users} />;
};
