import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  ButtonGroup,
  Checkbox,
  Div,
  FormItem,
  FormLayout,
  FormLayoutGroup,
  FormStatus,
  Group,
  IconButton,
  Input,
  Select,
  Spacing,
  Spinner,
  Subhead,
  Title,
} from '@vkontakte/vkui';
import { HelmetHeader, MyDiv, TagsSelect } from '../../Components';
import { Content } from './Content';
import { AddActions } from './AddActions';
import { useItem, usePopout, useTags, useUser } from '../../models';
import { cancelAction, getNegativeAction } from '../../utils/actions';
import { useQuery } from '../../hooks/useQuery';
import { useShareLink } from '../../hooks/useShareLink';
import { Icon24ShareExternal } from '@vkontakte/icons';
import styles from './Item.module.css';
import { favoriteItem } from '../../utils/api';
import {
  addContentItemAtEnd,
  addContentItemAtStart,
  clearItemData,
  deleteItem,
  saveItemToBackendFx,
  setItemTags,
  setParentForNewTag,
  setTitle
} from "../../models/item";

const noParent = 'нет (без родителя)';
const noParentEl = { label: noParent, value: noParent };

export const Item = () => {
  const { itemId } = useParams();
  const [query, setQuery] = useQuery();
  const { user } = useUser();
  const isNewItem = itemId === 'new';
  const [isEdit, setIsEdit] = useState(isNewItem || !!query.edit);
  const ref = useRef(query.el || '');
  const navigation = useNavigate();
  const { setPopout, clearPopout } = usePopout();
  const { item, loading, error, getItem } = useItem();
  const { snackbar, copySharedLink, linkToShared, shareItem } = useShareLink(item);

  const { tags: _tags, loading: tagsLoading, getTags, sharedTags } = useTags();
  const tags = [..._tags, ...sharedTags];

  useEffect(() => {
    if (itemId) {
      getItem(itemId);
      getTags();
      setIsEdit(isNewItem || !!query.edit);
    } else {
      clearItemData();
    }
    return clearItemData;
  }, [itemId]);

  useEffect(() => (item?.deleted ? navigation('/items', { replace: true }) : undefined), [item?.deleted]);
  useEffect(() => setQuery({}, { replace: true }), []);

  const handleDelete = () =>
    setPopout(
      <Alert
        actions={[cancelAction, getNegativeAction(() => deleteItem())]}
        actionsLayout="horizontal"
        onClose={clearPopout}
        header="Удаление документа"
        text="Вы уверены, что хотите удалить этот документ?"
      />,
    );

  const handleShare = () => {
    if (!itemId) {
      return;
    }
    shareItem().then(() => getItem(itemId));
  };

  const handleFavorite = () => {
    if (!itemId || !item) {
      return;
    }
    favoriteItem(itemId, !item.favorite).then(() => getItem(itemId));
  };

  if (!item && !loading && error) {
    return (
      <>
        <HelmetHeader title="Ошибка" hasBack />
        <FormStatus mode="error">{error}</FormStatus>
      </>
    );
  }

  if (!item || loading || tagsLoading) {
    return (
      <>
        <HelmetHeader title="Загрузка..." />
        <Spinner size="large" />
      </>
    );
  }

  const handleTag = (selectedTags: { value: string; label: string }[]) => {
    const resultTags = selectedTags.map(tag => {
      const existedTag = tags.find(el => el._id === tag.value);
      const existedItemTag = item.tags.find(el => el._id === tag.value);
      return (
        existedTag ||
        existedItemTag || { _id: tag.value, name: tag.label, isNew: true, owner: { _id: user._id, name: user.name } }
      );
    });
    setItemTags(resultTags);
  };

  const newTags = item.tags.filter(tag => tag.isNew);
  const parentTags = [
    noParentEl,
    ...tags
      // @ts-ignore
      .filter(tag => !tag.parent)
      .map(tag => ({
        label: tag.name,
        value: tag.name,
      })),
  ];

  const setParentForNewTagFn = (event: ChangeEvent<HTMLSelectElement>) => {
    const parent = tags.find(tag => tag.name === event.target.value);
    setParentForNewTag({ id: event.target.name, parent });
  };

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    saveItemToBackendFx(item)
      .then(({ data }) => {
        setIsEdit(false);
        if (typeof data === 'string') {
          navigation(`/item/${data}`, { replace: true });
        }
      })
      .catch(err => console.error(err));
  };

  const onCancelClick = () => {
    if (isNewItem) {
      navigation(-1);
      return;
    }
    getItem(item._id);
    setIsEdit(false);
  };

  const userIsOwner = user._id === item.owner?._id;

  return (
    <FormLayout onSubmit={onSubmit}>
      <HelmetHeader title={isNewItem ? 'new item' : item.title} hasBack />
      {!isNewItem && (
        <Group>
          <Div>
            {isEdit ? (
              <Button stretched onClick={handleDelete} appearance="negative" mode="secondary">
                Удалить
              </Button>
            ) : (
              <Button stretched onClick={() => setIsEdit(e => !e)} mode="outline">
                Редактировать
              </Button>
            )}
          </Div>
        </Group>
      )}
      {isEdit && (
        <Group>
          <FormItem top="Выберите или добавьте тэги">
            <TagsSelect onChange={handleTag} creatable options={tags} valueTags={item.tags} userId={user._id} />
          </FormItem>
          {newTags.map(tag => (
            <FormLayoutGroup mode="horizontal" segmented key={tag._id}>
              <FormItem>
                <Input value={tag.name} disabled />
              </FormItem>
              <FormItem>
                <Select name={tag._id} options={parentTags} defaultValue={noParent} onChange={setParentForNewTagFn} />
              </FormItem>
            </FormLayoutGroup>
          ))}
        </Group>
      )}
      {!isEdit && item.tags.length > 0 && (
        <Group>
          <MyDiv mode="horizontal" spacing={0}>
            <Subhead>Тэги:</Subhead>
            <Spacing size={5} />
            {item.tags.map(tag => (
              <div key={tag.name}>{tag.name}</div>
            ))}
          </MyDiv>
        </Group>
      )}
      {!isNewItem && !isEdit && (userIsOwner || item.shared) && (
        <Group>
          <MyDiv mode="horizontal" spacing={0}>
            <Subhead>Поделиться</Subhead>
            <Spacing size={5} />
            {userIsOwner && (
              <Checkbox checked={item.shared} onChange={handleShare}>
                {item.shared ? 'Доступен по ссылке' : 'Недоступен по ссылке'}
              </Checkbox>
            )}
            {item.shared && (
              <div className={styles.sharedIcon} onClick={copySharedLink}>
                <IconButton>
                  <Icon24ShareExternal />
                </IconButton>
                <Subhead>{linkToShared}</Subhead>
              </div>
            )}
          </MyDiv>
        </Group>
      )}
      {!isNewItem && !isEdit && (
        <Group>
          <Checkbox checked={item.favorite} onChange={handleFavorite}>
            Избранное
          </Checkbox>
        </Group>
      )}
      <Group>
        {isEdit ? (
          <>
            <FormItem top="Название">
              <Input value={item.title} onChange={e => setTitle(e.target.value)} required />
            </FormItem>
            <AddActions
              addContentItem={addContentItemAtStart}
              btnText={item.content.length > 0 ? 'Добавить новое поле в начало' : 'Добавить новое поле'}
            />
          </>
        ) : (
          <MyDiv mode="horizontal" spacing={0}>
            <Title level="2">{item.title}</Title>
          </MyDiv>
        )}

        <Content content={item.content} isEdit={isEdit} selectedItem={ref.current} />

        {error && <FormStatus mode="error">{error}</FormStatus>}
      </Group>
      {isEdit && (
        <>
          {item.content.length > 0 && (
            <Group>
              <AddActions addContentItem={addContentItemAtEnd} btnText="Добавить новое поле в конец" />
            </Group>
          )}
          <Group>
            <Div>
              <ButtonGroup stretched>
                <Button type="submit" stretched>
                  Сохранить
                </Button>
                <Button stretched onClick={onCancelClick} mode="secondary">
                  Отменить
                </Button>
              </ButtonGroup>
            </Div>
          </Group>
        </>
      )}
      {snackbar}
    </FormLayout>
  );
};
