import { DateObj } from '../types';

export const fromDateToObj = (_date?: Date) => {
  const date = _date || new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return {
    year,
    day,
    month,
  };
};

export const fromObjToDate = (dateObj: DateObj) => {
  const { year, month, day } = dateObj;
  return new Date(year, month - 1, day, 12);
};

const { year } = fromDateToObj();
export const minDate = { day: 1, month: 1, year: year - 100 };
export const maxDate = { day: 1, month: 1, year: year + 2 };
