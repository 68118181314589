import queryString from 'query-string';
import { logout } from '../models/logout';

export const GET = 'GET';
export const PUT = 'PUT';
export const POST = 'POST';
export const DELETE = 'DELETE';
export const FILE_POST = 'FILE_POST';
const defaultErrorText = 'Что-то пошло не так.';

type methodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'FILE_POST';

interface SendRequestProps {
  url: string;
  params?: Record<string, any>;
  method?: methodType;
  body?: BodyInit;
}

const isRequestSuccessful = (result: { code: 0 | 1 }) => result.code === 1;

export async function sendRequest<Response>({ url, params, method = GET, body }: SendRequestProps): Promise<Response> {
  const headers = new Headers();

  if (method.toUpperCase() === POST) {
    headers.append('Content-Type', 'application/json');
  }

  const response = await fetch(
    url + (method !== POST && method !== FILE_POST && params ? '?' + queryString.stringify(params) : ''),
    {
      headers,
      ...(params && method === POST && { body: JSON.stringify(params) }),
      ...(body && method === FILE_POST && { body }),
      method: method === FILE_POST ? POST : method,
      credentials: 'include',
    },
  );

  if (response.status === 401) {
    logout();
    throw new Error(defaultErrorText);
  }

  let result;
  try {
    result = await response.json();
  } catch (e) {
    throw new Error(defaultErrorText);
  }

  if (result == null || result.code === undefined || (result.code !== 1 && result.code !== 0)) {
    throw new Error(defaultErrorText);
  }

  const successful = isRequestSuccessful(result);

  if (successful) {
    return result;
  }

  throw new Error(result.message || defaultErrorText);
}
