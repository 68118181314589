import { ContentItem, ContentType } from '../types';
import { formatMyDate } from './formateDate';
import { copyTextToClipboard } from './copyTextToClipboard';

export const copyOneItemText = (item: ContentItem) => {
  let text = '';
  switch (item.type) {
    case ContentType.checkbox:
      text = item.name;
      break;
    case ContentType.date:
      text = formatMyDate(item.date);
      break;
    case ContentType.text:
      text = item.value || item.name;
      break;
  }
  return copyTextToClipboard(text);
};

export const copyItemText = (item: ContentItem) => {
  let text = '';
  switch (item.type) {
    case ContentType.date:
      text = `${item.name}: ${formatMyDate(item.date)}`;
      break;
    case ContentType.text:
      text = `${item.name}: ${item.value}`;
      break;
  }
  return copyTextToClipboard(text);
};
