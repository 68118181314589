import React, { SyntheticEvent, useEffect, useState } from 'react';
import { HelmetHeader, MyDiv } from '../../Components';
import { Headline, Group, Input, SimpleCell, IconButton, Spinner } from '@vkontakte/vkui';
import {  useTags } from '../../models';
import { Icon24ChevronRight } from '@vkontakte/icons';
import { TagType } from '../../types';
import { useNavigate } from 'react-router-dom';
import { setSearchTags, setSearchText } from "../../models/search";

const getTagSubtitle = (tag: TagType) => {
  const res = [];
  if (tag.parent?.name) {
    res.push(tag.parent.name);
  }

  if (tag.sharedWith?.length) {
    res.push(`Расшарено для: ${tag.sharedWith.map(el => el.name).join(', ')}`);
  }

  return res.join(', ');
};

export const Tags = () => {
  const [filter, setFilter] = useState('');
  const { tags, sharedTags } = useTags();
  const navigate = useNavigate();
  const [filteredTags, setFilteredTags] = useState(tags);
  const [filteredSTags, setSFilteredTags] = useState(sharedTags);

  const handleClick = (tag: TagType) => {
    setSearchTags([tag]);
    setSearchText('');
    navigate('/items');
  };

  const handleSettingsClick = (event: SyntheticEvent, id: string) => {
    event.stopPropagation();
    navigate(`/tag/${id}`);
  };

  useEffect(() => {
    const str = filter.toLowerCase();
    const newTags = tags.filter(
      tag => tag.name.toLowerCase().includes(str) || tag.parent?.name?.toLowerCase().includes(str),
    );
    setFilteredTags(newTags);
    const newSTags = sharedTags.filter(tag => tag.name.toLowerCase().includes(str));
    setSFilteredTags(newSTags);
  }, [filter, tags, sharedTags]);

  if (tags.length === 0) {
    return (
      <>
        <HelmetHeader title="Загрузка..." />
        <Spinner size="large" />
      </>
    );
  }
  return (
    <>
      <HelmetHeader title="Тэги" hasBack />
      <Group>
        <MyDiv>
          <Input value={filter} onChange={event => setFilter(event.target.value)} placeholder="Фильтр" />
        </MyDiv>
      </Group>
      <Group>
        <MyDiv>
          <Headline level="1" weight="1">
            Мои тэги
          </Headline>
        </MyDiv>
        {filteredTags.map(tag => (
          <SimpleCell
            key={tag._id}
            subtitle={getTagSubtitle(tag)}
            after={
              <IconButton onClick={event => handleSettingsClick(event, tag._id)}>
                <Icon24ChevronRight />
              </IconButton>
            }
            onClick={() => handleClick(tag)}
          >
            {tag.name}
          </SimpleCell>
        ))}
      </Group>
      {filteredSTags.length > 0 && (
        <Group>
          <MyDiv>
            <Headline level="1" weight="1">
              Доступные мне тэги
            </Headline>
          </MyDiv>
          {filteredSTags.map(tag => (
            <SimpleCell key={tag._id} subtitle={tag.owner.name} onClick={() => handleClick(tag)}>
              {tag.name}
            </SimpleCell>
          ))}
        </Group>
      )}
    </>
  );
};
