export enum Domains {
  vkcom = 'vkcom',
  vkui = 'vkui',
}

export const DOMAIN_TYPES = [Domains.vkcom, Domains.vkui];

export const DOMAINS: Record<Domains, string[]> = {
  [Domains.vkcom]: ['auto'],
  [Domains.vkui]: ['toy', 'snow', 'silver'],
};

export const VKUI_APPS = ['community_manage', 'community_create'];
